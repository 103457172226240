<template>
    <layout-full-width :title="$tc('product', 2)">
        <component-actions :action-filters="[]"
                           :itemsPerPage="itemsPerPage"
                           :page="1"
                           :primary-actions="[]"
                           :total="0"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @search="onSearch"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">
                                <button class="btn btn-checkbox">
                                    <font-awesome-icon :icon="['far', 'square']"/>
                                </button>
                            </th>
                            <th scope="col">{{ $t('product-name') }}</th>
                            <th scope="col">{{ $t('product-description') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template>
                            <tr v-for="(subscription, i) in profile.subscriptions"
                                :key="'subscription-' + i">
                                <td scope="col">
                                    <button class="btn btn-checkbox">
                                        <font-awesome-icon :icon="['far', 'square']"/>
                                    </button>
                                </td>
                                <td scope="col">{{ subscription.subscriptionDescLong }}</td>
                                <td scope="col">{{ subscription.portfolioElementDescLong }}</td>
                            </tr>
                        </template>
                        <tr v-if="!profile.subscriptions">
                            <td colspan="99">
                                <div
                                    class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                    role="alert">
                                    <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                    <div class="mt-2 fs-4">{{ $t('pages.products.no-products-found') }}</div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from '@/components/ui/ComponentActions.vue';

export default {
    name: "PageProducts",
    components: {LayoutFullWidth, ComponentActions},
    data() {
        return {
            loading: true,
            itemsPerPage: 10,
        }
    },
    computed: {
        profile() {
            return this.$store.state.USER.profile;
        }
    },
    methods: {
        async loadUserProfile() {
            await this.$store.dispatch('USER/GET_PROFILE');
            this.loading = false;
        },
        onChangePage() {
            console.log('onChangePage');
        },
        onSearch() {
            console.log('onSearch');
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.itemsPerPage = itemsPerPage;
        },
        onApplyFilters() {
            console.log('onApplyFilters');
        }
    },
    async beforeMount() {
        await this.loadUserProfile();
    }
}
</script>

<style lang="scss" scoped></style>